import React, { Component } from "react"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as mainActions from "redux/actions/main"

import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { getLsObject } from "helper"

const closed_msg =
	"Periode spin sedang ditutup, tunggu pembukaan periode spin selanjutnya. Jadwal pembukaan periode spin berikutnya akan diupdate melalui event info."

class SpinMachine extends Component {
	renderItem() {
		const { spin_item } = this.props.main
		return spin_item
			?.filter((item) => item.is_highlight)
			.map((item, index) => (
				<Slide index={index} key={"slide-item-" + index}>
					<div className="spin-machine__item">
						<img src={item.img_url} alt="" className="spin-machine__item-img" />
					</div>
				</Slide>
			))
	}
	doSpin(count) {
		const { open_popup, do_spin, put_data, toggle_popup } = this.props.actionsMain
		const { setting } = this.props.main
		const notShowModalBuy = getLsObject("not-show-modal-buys")
		if (!setting.is_spin_open) {
			put_data("err_msg", closed_msg)
			toggle_popup(true, "error")
			return
		}
		if (count === 1) {
			if (notShowModalBuy) {
				do_spin(1)
			} else {
				open_popup("confirmation-spin", { spin_times: 1, spin_price: setting?.spin_1_price || 20 })
			}
		}
		if (count === 10) {
			if (notShowModalBuy) {
				do_spin(10)
			} else {
				open_popup("confirmation-spin", { spin_times: 10, spin_price: setting?.spin_10_price || 180 })
			}
		}
	}

	render() {
		const { spin_item, setting, lang_json } = this.props.main
		const { open_popup } = this.props.actionsMain
		const filter = spin_item?.filter((item) => item.is_highlight)

		return (
			<div className="spin-machine">
				<div className="spin-machine__title">
					<img src={lang_json?.img_title_caption} alt="" className="spin-machine__title-img" />
				</div>
				<div className="spin-machine__item-list">
					<CarouselProvider
						naturalSlideWidth={10}
						naturalSlideHeight={10}
						totalSlides={filter.length}
						visibleSlides={filter.length < 3 ? filter.length || 0 : 3}
						infinite={true}
						isPlaying={true}
					>
						<Slider className="spin-machine__slider">{this.renderItem()}</Slider>
					</CarouselProvider>
				</div>
				<div className="spin-machine__bg-wrapper">
					<img src={lang_json?.img_main_prize} alt="" className="spin-machine__bg" />
					<button className="spin-machine__btn-history" onClick={() => open_popup("spin-history")}>
						<img src={lang_json?.img_btn_history} alt="" />
					</button>
				</div>
				<div className="spin-machine__btn-group">
					<button onClick={() => this.doSpin(1)}>
						<span style={{color: "black"}}>SPIN 1</span>
						<span style={{color: "black"}}>
							{setting.spin_1_price} <img src={lang_json?.img_cp_icon} alt="" className="coin" />
						</span>
					</button>
					<button onClick={() => this.doSpin(10)}>
						<span style={{color: "black"}}>SPIN 10</span>
						<span style={{color: "black"}}>
							{setting.spin_10_price} <img src={lang_json?.img_cp_icon} alt="" className="coin" />
						</span>
					</button>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SpinMachine)

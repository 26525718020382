import React from "react";
import PropTypes from "prop-types";
import Modal from "./index.js";
import { useSelector } from "react-redux";

const EventInfo = (props) => {
	const { isOpen, closeModal } = props;
	const { lang_json } = useSelector((state) => state?.main);

	return (
		<Modal
			isOpen={isOpen}
			className={"modal_info"}
			closeModal={() => {
				closeModal();
			}}
			title={<img src={lang_json?.img_title_event_info} alt="" />}
			scrollable={true}
			button={
				<button onClick={() => closeModal()}>
					<img src={lang_json?.img_btn_close} alt="" className="close" />
				</button>
			}
		>
			<div className="event-info" dangerouslySetInnerHTML={{ __html: lang_json?.txt_event_info }} />
		</Modal>
	);
};

EventInfo.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func
};

export default EventInfo;

import React, { Component } from 'react';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

// COMPONENTS
import Header from 'partial/Header';
import SpinMachine from 'partial/SpinMachine';
import ItemList from 'partial/ItemList';
import ModalDutyFragmentHistory from 'partial/Modal/DutyFragmentHistory';
import ModalSpinHistory from 'partial/Modal/SpinHistory';
import ModalEventInfo from 'partial/Modal/EventInfo';
import ModalConfirmationBuy from 'partial/Modal/ConfimationBuy';
import ModalConfirmationSpin from 'partial/Modal/ConfimationSpin';
import ModalConfirmationExchange from 'partial/Modal/ConfimationExchange';
import ModalGeneral from 'partial/Modal/General';
import ModalReveal from 'partial/Modal/GiftReveal';

// const HOME_BG = require('assets/img/CODMID_h5_duty-shop_BG.jpg'); 
class Home extends Component {
    
	render() {
		const { close_popup, is_popup_open, do_spin, convert_fragment, buy_shop_item } = this.props.actionsMain;
		const { popup_data, err_msg, popup_type, setting } = this.props.main;
        
		return (
			<div className="home">
				{/* <img src={HOME_BG} alt="" className="home__bg" /> */}
               
				<div className="home__body">
					<Header />
					<div className="home__content">
						<ItemList />
						<SpinMachine />
					</div>
				</div>
				<ModalReveal
					isOpen={is_popup_open('item-reveal')}
					closeModal={() => close_popup()}
					items={popup_data}
				/>
				<ModalDutyFragmentHistory
					isOpen={is_popup_open('df-history')}
					closeModal={() => close_popup()}
				/>
				<ModalGeneral
					isOpen={is_popup_open('error') || is_popup_open('error-uncloseable')}
					closeModal={() => close_popup()}
					message={err_msg}
					type={popup_type}
				/>
				<ModalSpinHistory
					isOpen={is_popup_open('spin-history')}
					closeModal={() => close_popup()}
				/>
				<ModalEventInfo
					isOpen={is_popup_open('event-info')}
					closeModal={() => close_popup()}
				/>
				<ModalConfirmationBuy
					isOpen={is_popup_open('confirmation-buy')}
					closeModal={() => close_popup()}
					onSubmit={() => buy_shop_item(popup_data?.id)}
					emblemNum={popup_data?.price_emblem}
					fragmentNum={popup_data?.price_fragment}
					img={popup_data?.img_url}
				/>
				<ModalConfirmationSpin
					isOpen={is_popup_open('confirmation-spin')}
					closeModal={() => close_popup()}
					onSubmit={() => do_spin(popup_data?.spin_times)}
					cpNum={popup_data?.spin_price}
					spinNum={popup_data?.spin_times}
				/>
				<ModalConfirmationExchange
					isOpen={is_popup_open('confirmation-exchange')}
                    closeModal={() => close_popup()}
					onSubmit={(num) => convert_fragment(num)}
					convertRate={setting?.convert_rate || 0}
				/>
      </div>
    );
  }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React from "react";
import PropTypes from "prop-types";

// REDUX
import { connect } from "react-redux";

const Modal = (props) => {
	const { isOpen, closeModal, children, title, scrollable, button, className } = props;
	const { lang_json } = props?.main;

	return (
		<div className={`modal ${isOpen ? "" : "disable"}`}>
			<div className="modal__bg" onClick={() => button && closeModal()}>
				&nbsp;
			</div>
			<div className="modal__body">
				<img src={lang_json?.img_popup_bg} alt="" className="modal__content-bg" />
				<div className={`modal__content ${className}`}>
					<h3 className="modal__title">{title}</h3>
					<div className={`modal__content-main ${scrollable ? "scrollable" : ""}`}>{children}</div>
					{button ? <div className="modal__btn-group">{button}</div> : ""}
				</div>
			</div>
		</div>
	);
};

Modal.defaultProps = {
	showButton: true
};

Modal.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	onSubmit: PropTypes.func
};

function mapStateToProps(state) {
	return { main: state.main };
}

export default connect(mapStateToProps)(Modal);

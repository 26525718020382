import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Modal from "./index.js"
import { useSelector } from "react-redux"

const ADD_BTN = require("assets/img/CODMID_h5_duty-shop_button_tambah.png")
const REMOVE_BTN = require("assets/img/CODMID_h5_duty-shop_button_kurang.png")
const EXCHANGE_ICON = require("assets/img/CODMID_h5_duty-shop_icon_arrow.png")

const ConfirmationExchange = (props) => {
	const { isOpen, closeModal, onSubmit, convertRate } = props
	const [emblemNum, setEmblemNum] = useState(0)
	const [fragmentNum, setFragmentNum] = useState(0)
	const { lang_json } = useSelector((state) => state?.main)

	useEffect(() => {
		setFragmentNum(emblemNum * convertRate)
	}, [convertRate, emblemNum])

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => {
				closeModal()
			}}
			title={<img src={lang_json?.img_title_confirm} alt="" />}
			button={
				<>
					<button onClick={() => onSubmit(emblemNum)}>
						<img src={lang_json?.img_btn_draw} alt="" />
					</button>
					<button onClick={() => closeModal()}>
						<img src={lang_json?.img_btn_kembali} alt="" />
					</button>
				</>
			}
		>
			<div className="confirmation-exchange confirmation-buy--exchange">
				<div className="confirmation-buy__text">
					Akan menukar{" "}
					<span className="confirmation-buy__currency">
						{fragmentNum} <img src={lang_json?.img_fragment_icon} alt="" className="coin" />
					</span>
					<br />
					menjadi&nbsp;
					<span className="confirmation-buy__currency">
						{emblemNum} <img src={lang_json?.img_emblem_icon} alt="" className="coin" />
					</span>
				</div>
				<div className="confirmation-buy__exchange-input">
					<div className="left">
						<span className="text">{fragmentNum}</span>
						<img src={lang_json?.img_fragment_icon} alt="" className="confirmation-buy__coin" />
					</div>
					<img src={EXCHANGE_ICON} alt="" className="confirmation-buy__img" />
					<div className="right">
						<button onClick={() => emblemNum > 1 && setEmblemNum(emblemNum - 1)}>
							<img src={REMOVE_BTN} alt="" className="confirmation-buy__exchange-input-btn" />
						</button>
						<span className="text">{emblemNum}</span>
						<img src={lang_json?.img_emblem_icon} alt="" className="confirmation-buy__coin" />
						<button
							onClick={() => {
								setEmblemNum(emblemNum + 1)
							}}
						>
							<img src={ADD_BTN} alt="" className="confirmation-buy__exchange-input-btn" />
						</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

ConfirmationExchange.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	onSubmit: PropTypes.func,
}

export default ConfirmationExchange

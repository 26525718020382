import axios from "axios"
import { getCache, getUrlParam, removeParamUrl, setCache, throttle } from "helper"

const ls = window.localStorage

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
	transformRequest: [
		(data, headers) => {
			delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
			return data
		},
	],
})

export const open_popup = (tipe, data) => ({
	type: "TOGGLE_POPUP",
	bool: true,
	tipe,
	data,
})

export const close_popup = () => ({
	type: "TOGGLE_POPUP",
	bool: false,
})

export const is_popup_open = (type) => {
	return (dispatch, getState) => {
		const { popup_type, popup_open } = getState().main
		return popup_open && popup_type === type
	}
}

export const toggle_popup = (bool, tipe) => ({
	type: "TOGGLE_POPUP",
	bool,
	tipe,
})

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
})

export const append_history = (key, data) => ({
	type: "APPEND_HISTORY",
	key,
	data,
})

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
})

export const setToken = () => {
	return (dispatch) => {
		var token = getUrlParam("token")
		if (token) {
			ls.removeItem("cache")
			ls.setItem("token", token)
			removeParamUrl()
			window.location.href = window.location.origin
		}
	}
}

export const checkLogin = () => {
	return (dispatch) => {
		dispatch(put_data("loader", true))
		dispatch(setToken())

		axios
			.get("/oauth/check_login/")

			.then((resp) => {
				dispatch(put_data("login", resp.data.user))
				dispatch(get_wallet(true))
				dispatch(get_shop_item())
				dispatch(get_spin_item())
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message, true))
			})
			.then(() => {
				dispatch(put_data("loader", false))
			})
	}
}

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = () => {
	return (dispatch) => {
		cdn
			.get(`data.json?${Math.random() * 10000}`)
			.then((resp) => {
				dispatch(put_data("lang_json", resp.data.data))
			})
			.catch((err) => {})
	}
}

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg, uncloseable) => {
	return (dispatch) => {
		const error = getUrlParam("err")
		if (error) {
			const errorMapper = {
				1: "Missing user data",
				2: "Error GOP",
				3: "Kamu belum memiliki akun CODM",
				4: "Missing CODM profile",
				5: "Gagal login, silakan keluar dari app dan masuk kembali",
				m: "Website is under maintenance.",
				41: "Gagal login! Event sedang dalam maintenance. Silakan login kembali dalam kurun waktu 15 sampai 20 menit.",
			}
			dispatch(put_data("err_msg", errorMapper[error]))
			dispatch(toggle_popup(true, "error-uncloseable"))
			dispatch(toggle_loader(false))
			return
		}
		if (err_msg) {
			dispatch(put_data("err_msg", err_msg))
		}
		if (uncloseable) {
			dispatch(toggle_popup(true, "error-uncloseable"))
		} else {
			dispatch(toggle_popup(true, "error"))
		}
		dispatch(toggle_loader(false))
	}
}

export const get_shop_item = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get("api/shop/?q=item")
			.then((resp) => {
				dispatch(put_data("shop", resp.data.data))
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message))
			})
			.finally(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const buy_shop_item = (id) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		let formdata = new FormData()
		formdata.append("shop_id", id)

		axios
			.post("api/shop/", formdata)
			.then((resp) => {
				console.log("resp", resp)
				dispatch(open_popup("item-reveal", { ...resp.data.data }))
				dispatch(get_wallet())
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message))
			})
			.finally(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_spin_item = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get("api/spin/", {
				params: {
					q: "item",
				},
			})
			.then((resp) => {
				dispatch(put_data("spin_item", resp.data.data))
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message))
			})
			.finally(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_spin_history = (current_page = 1) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get("api/spin/", {
				params: {
					q: "history",
					page: current_page,
				},
			})
			.then((resp) => {
				const next_page = current_page + 1
				if (current_page === 1) {
					dispatch(
						put_data("spin_history", {
							...resp.data.data,
							next_page,
						})
					)
				} else {
					dispatch(
						append_history("spin_history", {
							...resp.data.data,
							next_page,
						})
					)
				}
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message))
			})
			.finally(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const do_spin = (spin_num) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		let formdata = new FormData()
		formdata.append("spin_times", spin_num)

		axios
			.post("api/spin/", formdata)
			.then((resp) => {
				const price = {
					cp: resp.data.data.cp,
					fragment: resp.data.data.fragment_count,
					emblem: resp.data.data.emblem_count,
				}
				dispatch(toggle_loader(false))
				dispatch(put_data("spin_history", { items: [], has_next: true, total_pages: 0, next_page: 1 }))
				dispatch(open_popup("item-reveal", { ...resp.data.data }))
				// dispatch(get_wallet());
				dispatch(deductWallet(price))
			})
			.catch((err) => {
				dispatch(toggle_loader(false))
				dispatch(catch_error(err?.response?.data?.message))
			})
	}
}

export const deductWallet = (price) => {
	return (dispatch, getState) => {
		let oriWallet = getCache("wallet")
		if (!oriWallet) oriWallet = getState().main.wallet
		const wallet = {
			cp: oriWallet.cp - price.cp,
			fragment: oriWallet.fragment + price.fragment,
			emblem: oriWallet.emblem + price.emblem,
		}
		dispatch(put_data("wallet", wallet))
		setCache("wallet", wallet)
	}
}

export const get_inventory_history = (type, current_page = 1) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get("api/inventory/", {
				params: {
					q: type,
					page: current_page,
				},
			})
			.then((resp) => {
				const next_page = current_page + 1
				if (current_page === 1) {
					dispatch(
						put_data(`duty_${type}`, {
							...resp.data.data,
							next_page,
						})
					)
				} else {
					dispatch(
						append_history(`duty_${type}`, {
							...resp.data.data,
							next_page,
						})
					)
				}
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message))
			})
			.finally(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_wallet = (useCache = false) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))
		if (throttle("wallet", 5000) && useCache) {
			const data = getCache("wallet")
			if (data) {
				dispatch(put_data(`wallet`, data))
				return
			}
		}

		axios
			.get("api/wallet/")
			.then((resp) => {
				dispatch(put_data(`wallet`, resp.data.data))
				setCache("wallet", resp.data.data)
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_setting = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get("api/setting/")
			.then((resp) => {
				dispatch(put_data(`setting`, resp.data.data))
				const todayTimestamp = new Date().getTime()
				const { is_maintenance, event_open, event_closed } = resp.data.data
				const startTimestamp = new Date(event_open).getTime()
				const endTimestamp = new Date(event_closed).getTime()

				if (is_maintenance) {
					dispatch(catch_error("maintenance", true))
				} else if (todayTimestamp < startTimestamp) {
					dispatch(catch_error("event_not_started", true))
				} else if (todayTimestamp > endTimestamp) {
					dispatch(catch_error("event_ended", true))
				} else {
					dispatch(checkLogin())
				}
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message, true))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const convert_fragment = (emblemNum) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		let formdata = new FormData()
		formdata.append("convert_amount", emblemNum)

		axios
			.post("api/convert/", formdata)
			.then((resp) => {
				dispatch(
					open_popup("item-reveal", {
						convertMsg: resp.data.data,
						name: "Secret Emblem",
						img_url:
							"https://web.cdn.id.garenanow.com/webid/CODM/h5/201124_dutyshop/rewards/CODMID_h5_duty-shop_reward_icon_box_dutyemblem_1.png",
					})
				)
				dispatch(get_wallet())
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.message))
			})
			.finally(() => {
				dispatch(toggle_loader(false))
			})
	}
}

import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import "intersection-observer"
import { useInView } from "react-intersection-observer"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as mainActions from "redux/actions/main"

import Modal from "./index.js"

const SpinHistory = (props) => {
	const { isOpen, closeModal } = props
	const { spin_history, login, lang_json } = props.main
	const { get_spin_history } = props.actionsMain

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	})

	const renderGiftList = () => {
		return spin_history.items?.map?.((item, index) => (
			<div className="gift-history__item" key={`history-${index}`}>
				<img className="gift-history__item-img" src={item.img_url} alt="" />
				<div className="gift-history__item-name">{item.item_name}</div>
			</div>
		))
	}

	const next = useCallback(() => {
		if (login && isOpen && spin_history?.has_next) {
			get_spin_history(spin_history?.next_page)
		}
	}, [get_spin_history, isOpen, login, spin_history.has_next, spin_history.next_page])

	useEffect(() => {
		if (inView) {
			next()
		}
	}, [inView, next])

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => {
				closeModal()
			}}
			title={<img src={lang_json?.img_title_history} alt="" />}
			scrollable={true}
			button={
				<button onClick={() => closeModal()}>
					<img src={lang_json?.img_btn_close} alt="" className="close" />
				</button>
			}
		>
			<div className="gift-history">
				{renderGiftList()}
				<div className="last-item" ref={ref}>
					&nbsp;
				</div>
			</div>
		</Modal>
	)
}

SpinHistory.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SpinHistory)

import React from "react"
import PropTypes from "prop-types"
import Modal from "./index.js"
import { useSelector } from "react-redux"

const DEFAULT_MESSAGE = "TERJADI KESALAHAN SERVER"
const MSG_MAPPER = {
	"wrong query": "wrong query",
	not_logged_in: "Please login in game",
	maintenance: "Event sedang dilakukan maintenance",
	event_not_started: "Event belum mulai",
	event_ended: "Event sudah berakhir",
	too_many_request: "Terlalu Banyak Request! Berikan sedikit jeda sebelum tiap aksi!",
}

const General = (props) => {
	const { isOpen, closeModal, message, showButton, type } = props
	const { lang_json } = useSelector((state) => state?.main)

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => {
				closeModal()
			}}
			button={
				type === "error" ? (
					<button onClick={() => closeModal()}>
						<img src={lang_json?.img_btn_close} alt="" />
					</button>
				) : (
					""
				)
			}
			// title="Pemberitahuan"
			showButton={showButton}
		>
			<div className="general">{message ? MSG_MAPPER[message] || message : DEFAULT_MESSAGE}</div>
		</Modal>
	)
}
General.defaultProps = {
	showButton: true,
}

General.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	showButton: PropTypes.bool,
}

export default General

import React, { Component } from "react";

export default class Item extends Component {
	render() {
		const { name, img, emblem, fragment, onClick, exchange, json } = this.props;
		return (
			<div className="item" onClick={() => onClick()}>
				<img src={json?.img_reward_box_bg} alt="" className="item__bg" />
				<div className="item__body">
					<div className="item__name">{name}</div>
					<img src={img} alt="" className="item__img" />
					<div className="item__btn">
						{exchange ? "Tukar" : "Beli"}{" "}
						{emblem ? (
							<>
								{emblem} <img src={json?.img_emblem_icon} alt="" className="coin" />
							</>
						) : (
							""
						)}
						{fragment ? (
							<>
								{fragment} <img src={json?.img_fragment_icon} alt="" className="coin" />
							</>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		);
	}
}

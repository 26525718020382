const main_state = {
	login: false,
	loader: false,
	lang_json: false,
	popup_open: false,
	popup_type: false,
	popup_data: {},
	err_msg: "",
	setting: false,
	shop: [],
	spin_item: [],
	spin_history: {
		items: [],
		has_next: true,
		total_pages: 0,
		next_page: 1,
	},
	duty_history: {
		items: [],
		has_next: true,
		total_pages: 0,
		next_page: 1,
	},
	duty_inventory: {
		items: [],
		has_next: true,
		total_pages: 0,
		next_page: 1,
	},
	wallet: false,
}

const main = (state = main_state, action) => {
	switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data }
		case "APPEND_HISTORY":
			return {
				...state,
				[action.key]: {
					...action.data,
					items: state[action.key].items.concat(action.data.items),
				},
			}
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data }
		case "TOGGLE_POPUP":
			if (action.bool) {
				return { ...state, popup_open: action.bool, popup_type: action.tipe, popup_data: action.data || {} }
			} else {
				// + cleanup
				return {
					...state,
					popup_open: action.bool,
					popup_type: action.tipe,
					popup_data: action.data || {},
					spin_history: {
						...state.spin_history,
						next_page: 1,
						has_next: true,
					},
					duty_history: {
						...state.duty_history,
						next_page: 1,
						has_next: true,
					},
					duty_inventory: {
						...state.duty_inventory,
						next_page: 1,
						has_next: true,
					},
					err_msg: "",
				}
			}
		default:
			return state
	}
}

export default main

import React, { Component } from "react"
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"

// Component
import ViewportRestrict from "helper/component/viewport"
import Loader from "helper/component/loader"
import NotFound from "helper/component/404"

// Pages
import Home from "pages/Home"
import ErrorBoundary from "ErrorBoundary"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as mainActions from "redux/actions/main"

class Main extends Component {
	componentDidMount() {
		this.props.actionsMain.get_setting()
		this.props.actionsMain.get_translate()
	}

	render() {
		const { loader, lang_json } = this.props.main

		return (
			<Router basename={process.env.PUBLIC_URL}>
				<div id="Main" className="main-panel" style={{ backgroundImage: `url(${lang_json?.img_bg_main})` }}>
					<ErrorBoundary>
						<ViewportRestrict display={true} type="portrait" />
						<Loader loader={loader} />
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={Home} />
								<Route component={NotFound} />
							</Switch>
						</div>
					</ErrorBoundary>
				</div>
			</Router>
		)
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)

import React from "react";
import PropTypes from "prop-types";
import Modal from "./index.js";
import { useSelector } from "react-redux";

const GiftReveal = (props) => {
	const { isOpen, closeModal, items } = props;
	const { lang_json } = useSelector((state) => state?.main);

	const renderGiftList = () => {
		if (items?.name) {
			return (
				<div className="gift-reveal__item">
					<img className="gift-reveal__item-img" src={items.img_url} alt="" />
					<div className="gift-reveal__item-name">{items.name}</div>
				</div>
			);
		}
		if (items?.prize) {
			return items.prize.map((item, index) => (
				<div className="gift-reveal__item" key={`gift-${index}`}>
					<img className="gift-reveal__item-img" src={item.img_url} alt="" />
					<div className="gift-reveal__item-name">{item.name}</div>
				</div>
			));
		}
		return;
	};

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => {
				closeModal();
			}}
			title={<img src={lang_json?.img_title_congrats} alt="" />}
		>
			<div className="gift-reveal">
				{items?.convertMsg ? (
					<>
						<div className="gift-reveal__title">{items?.convertMsg}</div>
						<div className={`gift-reveal__list single`}>{renderGiftList()}</div>
					</>
				) : (
					<>
						<div className="gift-reveal__title">Kamu Mendapatkan</div>
						<div
							className={`gift-reveal__list ${
								items && ((items.prize && items.prize.length === 1) || items.name) ? "single" : ""
							}`}
						>
							{renderGiftList()}
						</div>
					</>
				)}
				<button
					className="custom-close"
					style={{ backgroundImage: `url(${lang_json?.img_btn_close})` }}
					onClick={() => closeModal()}
				></button>
			</div>
		</Modal>
	);
};

GiftReveal.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func
};

export default GiftReveal;

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Modal from "./index.js"
import { getLsObject, setLsObject } from "helper/index.js"
import { useSelector } from "react-redux"

const ConfirmationSpin = (props) => {
	const { isOpen, closeModal, onSubmit, cpNum, spinNum } = props
	const [showModal, setShowModal] = useState(true)
	const { lang_json } = useSelector((state) => state?.main)

	const draw = () => {
		if (!showModal) {
			setLsObject("not-show-modal-buys", true)
		}
		onSubmit()
	}

	useEffect(() => {
		const notShowModalBuy = getLsObject("not-show-modal-buys")
		setShowModal(!notShowModalBuy)
	}, [isOpen])

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => {
				closeModal()
			}}
			title={<img src={lang_json?.img_title_confirm} alt="" />}
			className="closer-to-middle"
			button={
				<>
					<button onClick={() => draw()} style={{position: "relative"}}>
						<img src={lang_json?.img_btn_spin_empty} alt="" className="show"/>
						<div className="btn-confirmation-spin" style={{color: "black"}}>SPIN</div>
					</button>
					<button onClick={() => closeModal()}>
						<img src={lang_json?.img_btn_kembali} alt="" />
					</button>
				</>
			}
		>
			<div className="confirmation-buy confirmation-buy--spin">
				<div className="confirmation-buy__text">
					{lang_json?.txt_confirmation_use}{" "}
					<span className="confirmation-buy__currency">
						{cpNum || 0} <img src={lang_json?.img_cp_icon} alt="" className="coin" />
					</span>{" "}
					{(lang_json?.txt_confirmation_currency)?.replace('{currency}', (spinNum || 0))}
				</div>
				<div className="confirmation-buy__toggle" onClick={() => setShowModal(!showModal)}>
					<div className="checkbox">
						<img src={lang_json?.img_popup_checkbox} alt="" className="checkbox__bg show" />
						<img src={lang_json?.img_popup_check} alt="" className={`checkbox__mark show ${showModal ? "disable" : ""}`} />
					</div>
					Jangan tunjukkan popup ini lagi
				</div>
			</div>
		</Modal>
	)
}

ConfirmationSpin.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	onSubmit: PropTypes.func,
}

export default ConfirmationSpin

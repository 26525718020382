import React, { Component } from "react"
import Item from "./Item"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as mainActions from "redux/actions/main"

const IMAGE_PLACEHOLDER = require("assets/img/CODMID_h5_duty-shop_reward_box_dutyemblem.png")

class ItemList extends Component {
	renderItem = () => {
		const { open_popup } = this.props.actionsMain
		const { shop, lang_json } = this.props.main

		return shop?.map((item) => (
			<Item
				key={"item-" + item.id}
				name={item.name}
				img={item.img_url}
				emblem={item.price_emblem}
				fragment={item.price_fragment}
				json={lang_json}
				onClick={() => open_popup("confirmation-buy", { ...item })}
			/>
		))
	}

	render() {
		const { open_popup } = this.props.actionsMain
		const { lang_json } = this.props.main

		return (
			<div className="item-list">
				<img src={lang_json?.img_shop_bg} alt="" className="item-list__bg" />
				<div className="item-list__body">
					<div className="item-list__content">
						<Item
							key="item-exchange"
							name="Tukar Shards"
							exchange={true}
							img={IMAGE_PLACEHOLDER}
							json={lang_json}
							onClick={() => open_popup("confirmation-exchange")}
						/>
						{this.renderItem()}
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemList)

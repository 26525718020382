import React, { Component } from "react";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "redux/actions/main";

class Header extends Component {
	render() {
		const { open_popup } = this.props.actionsMain;
		const { login, wallet, lang_json } = this.props.main;

		return (
			<header className="header">
				<div className="header__btn-group">
					<button onClick={() => open_popup("event-info")}>
						<img src={lang_json?.img_btn_event_info} alt="" className="header__btn" />
					</button>
					<button
						onClick={() => {
							open_popup("df-history");
						}}
					>
						<img src={lang_json?.img_btn_history_df} alt="" className="header__btn" />
					</button>
				</div>
				<img src={lang_json?.img_logo} alt="" className="header__logo" />
				<div className="header__user-info">
					<div className="header__username">Hi, {login?.codm_nickname || "Player"}!</div>
					<div className="header__user-attribute">
						{wallet?.cp || 0} <img src={lang_json?.img_cp_icon} alt="" className="coin" />
					</div>
					<div className="header__user-attribute">
						{wallet?.emblem || 0} <img src={lang_json?.img_emblem_icon} alt="" className="coin" />
					</div>
					<div className="header__user-attribute">
						{wallet?.fragment || 0} <img src={lang_json?.img_fragment_icon} alt="" className="coin" />
					</div>
				</div>
			</header>
		);
	}
}

function mapStateToProps(state) {
	return { main: state.main };
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from "react";
import PropTypes from "prop-types";

import Modal from "./index.js";
import { useSelector } from "react-redux";

const PLACEHOLDER_ITEM = require("assets/img/CODMID_wester_preanniv_prize_placeholder.png");

const ConfirmationBuy = (props) => {
	const { isOpen, closeModal, onSubmit, emblemNum, fragmentNum, img } = props;
	const { lang_json } = useSelector((state) => state?.main);

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => {
				closeModal();
			}}
			title={<img src={lang_json?.img_title_confirm} alt="" />}
			button={
				<>
					<button onClick={() => onSubmit()}>
						<img src={lang_json?.img_btn_draw} alt="" />
					</button>
					<button onClick={() => closeModal()}>
						<img src={lang_json?.img_btn_kembali} alt="" />
					</button>
				</>
			}
		>
			<div className="confirmation-exchange">
				<div className="confirmation-buy__text">
					Akan menukar{" "}
					{emblemNum ? (
						<span className="confirmation-buy__currency">
							{emblemNum} <img src={lang_json?.img_emblem_icon} alt="" className="coin" />
						</span>
					) : (
						""
					)}{" "}
					{emblemNum && fragmentNum ? "dan " : ""}
					{fragmentNum ? (
						<span className="confirmation-buy__currency">
							{fragmentNum} <img src={lang_json?.img_fragment_icon} alt="" className="coin" />
						</span>
					) : (
						""
					)}
					<br />
					untuk membeli item dibawah ini
				</div>
				<div className="item-img">
					<img src={img || PLACEHOLDER_ITEM} alt="" className="confirmation-buy__img" />
				</div>
			</div>
		</Modal>
	);
};

ConfirmationBuy.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	onSubmit: PropTypes.func
};

export default ConfirmationBuy;

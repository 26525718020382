import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import "intersection-observer"
import { useInView } from "react-intersection-observer"
// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as mainActions from "redux/actions/main"

import Modal from "./index.js"

const DutyFragmentHistory = (props) => {
	const { isOpen, closeModal } = props
	const { duty_inventory, duty_history, login, lang_json } = props.main
	const { get_inventory_history } = props.actionsMain
	const [currentTab, setCurrentTab] = useState("inventory")

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	})

	const renderItem = () => {
		if (currentTab === "inventory") {
			return duty_inventory?.items?.map((item, index) => (
				<tr key={"inventory-" + index}>
					<td>{item.item}</td>
					<td>{item.sum}</td>
					<td>{item.expiry__date}</td>
				</tr>
			))
		} else {
			return duty_history?.items?.map((item, index) => (
				<tr key={"df-history-" + index}>
					<td>{item.item}</td>
					<td>{item.amount}</td>
					<td>{item.status}</td>
					<td>{item.total}</td>
				</tr>
			))
		}
	}

	const next = useCallback(() => {
		if (login && isOpen) {
			if (currentTab === "inventory") {
				if (duty_inventory?.has_next) {
					get_inventory_history("inventory", duty_inventory?.next_page)
				}
			} else {
				if (duty_history?.has_next) {
					get_inventory_history("history", duty_history?.next_page)
				}
			}
		}
	}, [
		currentTab,
		duty_history.has_next,
		duty_history.next_page,
		duty_inventory.has_next,
		duty_inventory.next_page,
		get_inventory_history,
		isOpen,
		login,
	])

	useEffect(() => {
		if (inView) {
			next()
		}
	}, [inView, next])

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => {
				closeModal()
			}}
			title={<img src={lang_json?.img_title_history_df} alt="" />}
			button={
				<button onClick={() => closeModal()}>
					<img src={lang_json?.img_btn_close} alt="" className="close" />
				</button>
			}
		>
			<div className="df-history">
				<div className="df-history__nav">
					<button onClick={() => setCurrentTab("inventory")}>
						<img
							src={currentTab === "inventory" ? lang_json?.img_tab_inventory_active : lang_json?.img_tab_inventory_inactive}
							alt=""
							className={`df-history__nav-item show ${currentTab === "inventory" ? "active" : ""}`}
						/>
					</button>
					<button onClick={() => setCurrentTab("history")}>
						<img
							src={currentTab === "history" ? lang_json?.img_tab_history_active : lang_json?.img_tab_history_inactive}
							alt=""
							className={`df-history__nav-item show ${currentTab === "history" ? "active" : ""}`}
						/>
					</button>
				</div>
				<div className="df-history__content">
					<img src={lang_json?.img_title_duty_fragments} alt="" className="df-history__content-title" />
					<table className="df-history__content-table">
						<thead>
							<tr>
								<th>ITEM</th>
								<th>QTY</th>
								{currentTab === "history" ? (
									<>
										<th>INFO</th>
										<th>SALDO</th>
									</>
								) : null}
								{currentTab === "inventory" ? (
									<>
										<th>EXPIRING DATE</th>
									</>
								) : null}
							</tr>
						</thead>
						<tbody>
							{renderItem()}
							<tr ref={ref}>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Modal>
	)
}

DutyFragmentHistory.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DutyFragmentHistory)
